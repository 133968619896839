import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
    ADVANTAGES,
    BUSINESS_BENEFITS,
    COMPANY_LOGOS,
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS_LIST,
    SECTIONS_ID,
    SECTIONS_TRANSLATION,
    TECHNOLOGICAL_BENEFITS,
} from 'views/react-native/constants';
import { PopularCompaniesSection } from 'views/react-native/popular-companies-section';
import { WhatIsReactNativeSection } from 'views/react-native/what-is-react-native-section';
import { CONSTANTS } from 'constants/styles/constants';
import { FooterSection } from 'modules/footer-section';
import { BookConsultationSection } from 'modules/technologies/book-consultation-section';
import { BusinessBenefitsSection } from 'modules/technologies/business-benefits-section';
import { AnimatedBusinessBenefits } from 'modules/technologies/business-benefits-section/components/animated-business-benefits';
import { CodeConsultingSection } from 'modules/technologies/code-consulting-section';
import { HeaderSection } from 'modules/technologies/header-section';
import { AnimatedDevelopment } from 'modules/technologies/header-section/components/animated-native-development';
import { HireDevelopersSection } from 'modules/technologies/hire-developers-section';
import { ObjectiveAdvantagesSection } from 'modules/technologies/objective-advantages-section';
import { RealResultsSection } from 'modules/technologies/real-results-section';
import { SectionsNavigationBar } from 'modules/technologies/sections-navigation-bar';
import { TechnologicalBenefitsSection } from 'modules/technologies/technological-benefits-section';
import { WorkWithMRSection } from 'modules/technologies/work-with-mr-section';
import { array } from 'prop-types';
import BusinessBenefits from 'svgs/technologies/react-native/business-benefits.svg';
import NativeDevelopment from 'svgs/technologies/react-native/native-development.svg';

const Container = styled.div`
    width: 100%;
    padding-top: ${CONSTANTS.MARGIN.PAGE};
`;

const Props = {
    faqData: array.isRequired,
};

export const ReactNativeView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <HeaderSection
                title="react-native.header.title"
                descriptions={['react-native.header.description']}
                img={
                    <AnimatedDevelopment>
                        <NativeDevelopment />
                    </AnimatedDevelopment>
                }
                alt="react-native.header.imgAlt"
            />
            <SectionsNavigationBar
                data={SECTIONS_TRANSLATION}
                ids={SECTIONS_ID}
            />
            <CodeConsultingSection
                id={SECTIONS_ID[0]}
                title="react-native.topics.codeConsulting"
                subTitle="react-native.codeConsulting.subTitle"
                descriptions={[
                    'react-native.codeConsulting.descriptions.0',
                    'react-native.codeConsulting.descriptions.1',
                ]}
            />
            <WhatIsReactNativeSection />
            <BookConsultationSection />
            <RealResultsSection
                id={SECTIONS_ID[1]}
                title="react-native.topics.realResults"
                description="react-native.realResults.description"
                logos={COMPANY_LOGOS}
            />
            <PopularCompaniesSection />
            <TechnologicalBenefitsSection
                id={SECTIONS_ID[2]}
                data={TECHNOLOGICAL_BENEFITS}
                title="react-native.technologicalBenefits.title"
                paragraph={formatMessage({
                    id: 'react-native.technologicalBenefits.subTitle',
                })}
            />
            <ObjectiveAdvantagesSection
                id={SECTIONS_ID[3]}
                data={ADVANTAGES}
                title="react-native.topics.objectiveAdvantages"
                subTitle="react-native.objectiveAdvantages.subTitle"
                rootPath="react-native.objectiveAdvantages.reasons"
                subTitleDescription="react-native.objectiveAdvantages.subTitleDescription"
                reasonsTitle="react-native.objectiveAdvantages.reasons.title"
            />
            <HireDevelopersSection
                id={SECTIONS_ID[4]}
                title="react-native.topics.hireDevelopers"
                description="react-native.ourDevelopers"
            />
            <BusinessBenefitsSection
                id={SECTIONS_ID[5]}
                logo={
                    <AnimatedBusinessBenefits>
                        <BusinessBenefits />
                    </AnimatedBusinessBenefits>
                }
                data={BUSINESS_BENEFITS}
                title="react-native.topics.businessBenefits"
                description="react-native.businessBenefits.description"
            />
            <WorkWithMRSection
                id={SECTIONS_ID[6]}
                title="react-native.workWithMobileReality.title"
                firstRowDescriptions={[
                    'react-native.workWithMobileReality.atMobileReality',
                    'react-native.workWithMobileReality.agileDevelopment',
                ]}
                secondRowDescriptions={[
                    'react-native.workWithMobileReality.collaborativeApproach',
                ]}
                perksData={PERKS_LIST}
                firstRowAlt={formatMessage({
                    id: 'react-native.workWithMobileReality.firstRowAlt',
                })}
                secondRowAlt={formatMessage({
                    id: 'react-native.workWithMobileReality.secondRowAlt',
                })}
            />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </Container>
    );
};

ReactNativeView.propTypes = Props;
