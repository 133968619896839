import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import CliIcon from 'svgs/technological-benefits/ic-cli.svg';
import DocumentIcon from 'svgs/technological-benefits/ic-document-code.svg';
import MessageIcon from 'svgs/technological-benefits/ic-message-programming.svg';
import TrendIcon from 'svgs/technological-benefits/ic-trend-down.svg';
import MaintenanceIcon from 'svgs/technologies/react-native/business-benefits-items/maintenance.svg';
import MarketReachIcon from 'svgs/technologies/react-native/business-benefits-items/mobile.svg';
import UXIcon from 'svgs/technologies/react-native/business-benefits-items/monitor.svg';
import EfficiencyIcon from 'svgs/technologies/react-native/business-benefits-items/trend-up.svg';
import WalletIcon from 'svgs/technologies/react-native/business-benefits-items/wallet-check.svg';
import InstagramLogo from 'svgs/technologies/react-native/companies/logo-instagram.svg';
import PinterestLogo from 'svgs/technologies/react-native/companies/logo-pinterest.svg';
import SkypeLogo from 'svgs/technologies/react-native/companies/logo-skype.svg';
import TeslaLogo from 'svgs/technologies/react-native/companies/logo-tesla.svg';
import UberLogo from 'svgs/technologies/react-native/companies/logo-uber.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';

const iconProps = {
    width: 42,
    height: 42,
};

export const BUSINESS_BENEFITS = [
    {
        title: 'react-native.businessBenefits.benefits.fasterDevelopment.title',
        description:
            'react-native.businessBenefits.benefits.fasterDevelopment.description',
        icon: <WalletIcon {...iconProps} />,
    },
    {
        title: 'react-native.businessBenefits.benefits.ux.title',
        description: 'react-native.businessBenefits.benefits.ux.description',
        icon: <UXIcon {...iconProps} />,
    },
    {
        title: 'react-native.businessBenefits.benefits.efficiency.title',
        description:
            'react-native.businessBenefits.benefits.efficiency.description',
        icon: <EfficiencyIcon {...iconProps} />,
    },
    {
        title: 'react-native.businessBenefits.benefits.maintenance.title',
        description:
            'react-native.businessBenefits.benefits.maintenance.description',
        icon: <MaintenanceIcon {...iconProps} />,
    },
    {
        title: 'react-native.businessBenefits.benefits.marketReach.title',
        description:
            'react-native.businessBenefits.benefits.marketReach.description',
        icon: <MarketReachIcon {...iconProps} />,
    },
];

export const COMPANY_LOGOS = [
    {
        logo: <UberLogo />,
    },
    {
        logo: <TeslaLogo />,
    },
    {
        logo: <SkypeLogo />,
    },
    {
        logo: <InstagramLogo />,
    },
    {
        logo: <PinterestLogo />,
    },
];

export const SECTIONS_TRANSLATION = [
    'react-native.topics.codeConsulting',
    'react-native.topics.realResults',
    'react-native.topics.technologicalBenefits',
    'react-native.topics.objectiveAdvantages',
    'react-native.topics.hireDevelopers',
    'react-native.topics.businessBenefits',
    'react-native.topics.whyWorkWithMobileReality',
];

export const SECTIONS_ID = [
    'react-native-code-consulting',
    'react-native-real-results',
    'react-native-technological-benefits',
    'react-native-objective-advantages',
    'react-native-hire-developers',
    'react-native-business-benefits',
    'react-native-why-work-with-mobile-reality',
];

export const ADVANTAGES = [
    {
        index: 1,
        section: 'singleCodebase',
        paragraphsCount: 3,
    },
    {
        index: 2,
        section: 'nativity',
        paragraphsCount: 1,
    },
    {
        index: 3,
        section: 'libraries',
        paragraphsCount: 1,
    },
    {
        index: 4,
        section: 'typescript',
        paragraphsCount: 3,
    },
    {
        index: 5,
        section: 'ui',
        paragraphsCount: 1,
    },
];

export const PERKS_LIST = [
    {
        title: 'react-native.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'react-native.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'react-native.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'react-native.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const TECHNOLOGICAL_BENEFITS = [
    {
        icon: <TrendIcon {...iconProps} />,
        text: 'react-native.technologicalBenefits.minimizeMistakes.title',
        description:
            'react-native.technologicalBenefits.minimizeMistakes.description',
    },
    {
        icon: <CliIcon {...iconProps} />,
        text: 'react-native.technologicalBenefits.fulfilledRequirements.title',
        description:
            'react-native.technologicalBenefits.fulfilledRequirements.description',
    },
    {
        icon: <DocumentIcon {...iconProps} />,
        text: 'react-native.technologicalBenefits.improveQuality.title',
        description:
            'react-native.technologicalBenefits.improveQuality.description',
    },
    {
        icon: <MessageIcon {...iconProps} />,
        text: 'react-native.technologicalBenefits.codeReadability.title',
        description:
            'react-native.technologicalBenefits.codeReadability.description',
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.REACT_NATIVE],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'react-native.footer.button',
    },
    categoryBanner: {
        title: 'react-native.footer.title',
        description: 'react-native.footer.description',
        svg: JavascriptSvg,
    },
};
