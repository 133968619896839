import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 750px;
    display: flex;
    flex-direction: column;
    margin-top: 9.375rem;
    gap: 3rem;

    .custom {
        align-items: flex-start;
    }
`;

export const PopularCompaniesSection = () => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            codingWithCoffee: file(
                relativePath: {
                    eq: "technologies/react-native/coding-with-coffee.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            applicationCode: file(
                relativePath: {
                    eq: "technologies/react-native/application-code.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return (
        <SContainer>
            <RowWithTextImg
                descriptions={[
                    'react-native.popularChoice',
                    'react-native.oneSuchCompany',
                ]}
                img={images.codingWithCoffee}
                alt={formatMessage({ id: 'react-native.popularChoiceImgAlt' })}
                className="custom"
            />
            <RowWithTextImg
                descriptions={[
                    'react-native.anotherGlobalCorporation',
                    'react-native.otherCompanies',
                ]}
                img={images.applicationCode}
                alt={formatMessage({
                    id: 'react-native.anotherGlobalCorporationAlt',
                })}
                className="custom"
                isReversed
            />
        </SContainer>
    );
};
