import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 500px;
`;

const STitle = styled(HeaderSecond)`
    text-align: center;
`;

const SGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 3.75rem 0 6.25rem;
    gap: 4rem;

    ${CONSTANTS.MEDIA.max600`
        grid-template-columns: 1fr;
    `};
`;

const STextTile = styled.div`
    padding-left: 1rem;
    height: fit-content;
    border-left: 2px solid var(--primary-color);
`;

const descriptions = [
    'react-native.reactNativeIsAJavaScriptFramework',
    'react-native.whatMakesReactNativeSpecial',
    'react-native.anotherSignificantAdvantage',
    'react-native.overallReactNative',
];

export const WhatIsReactNativeSection = () => {
    return (
        <SContainer margin={false}>
            <STitle>
                <FormattedMessage id="react-native.whatIsReactNative" />
            </STitle>
            <SGrid>
                {descriptions.map((description, index) => (
                    <STextTile key={index}>
                        <FormattedMessage id={description} />
                    </STextTile>
                ))}
            </SGrid>
        </SContainer>
    );
};
